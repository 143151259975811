     <template>
<div>
    <!-- <v-row v-show="$vuetify.breakpoint.mobile"></v-row>
    <v-row style="margin-top: 0%" v-show="!$vuetify.breakpoint.mobile"></v-row> -->
    <v-dialog v-model="dialog" width="250">
        <v-card>
            <v-card-title class="text-purple" style="font-weight: 300; font-size: 16px">
                {{ selected_row.provider_name }}
                <!-- {{ selected_row.booking_rec_id}} -->
            </v-card-title>
            <v-card-text>
                <v-list-item three-line>
                    <!-- {{currentDate()}}
                {{new Date(selected_row.booking_date)}} -->
                    <v-list-item-content>
                        <!-- <v-list-item-title><b>User id : </b> &nbsp;{{ selected_row.user_id }}</v-list-item-title> -->
                        <v-list-item-title><b>ชื่อ : </b> &nbsp; {{ selected_row.patient_name }}</v-list-item-title>
                        <v-list-item-title><b>สถานะไลน์ : </b> &nbsp;{{ selected_row.line_status ? 'มีไลน์' : 'ไม่มีไลน์' }}</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </v-card-text>
            <v-card-actions style="    justify-content: space-between;">
                <v-btn @click="cf_dialog = true" class="ma-2" x-small outlined fab color="red">
                    <v-icon>delete_forever</v-icon>
                </v-btn>
                &nbsp;
                <v-btn color="bg-purple text-white" @click="dialog = false">ตกลง</v-btn>

            </v-card-actions>
        </v-card>
    </v-dialog>
        <v-dialog v-model="cf_dialog" width="300">
            <v-card>
                <v-card-text style="text-align:center">
                    <br>
                    <v-btn class="ma-2" small outlined fab color="red">
                        <v-icon>delete_forever</v-icon>
                    </v-btn>
                    <br>
                    <span>ยืนยันการลบผู้ป่วยจากการดูแล</span>
                    
                    <br>
                </v-card-text>
                <v-card-actions class="d-flex justify-space-around">
                    <v-spacer></v-spacer>
                    <v-btn small outlined style="color:purple" @click="cf_dialog = false">ยกเลิก</v-btn>
                    <v-spacer></v-spacer>
                    <v-btn small color="bg-purple text-white" @click="delRelation">ตกลง</v-btn>
                    <v-spacer></v-spacer>
                </v-card-actions>
            </v-card>
        </v-dialog>
    <v-container>
        <v-row class="align-center d-flex h-0 pb-0 pt-2">
            <v-col lg="3" sm="8" class="mx-auto">
                <!-- <v-row class="d-flex">
                    <v-col cols="12" style="text-align: center;padding-bottom: 0px;"><span class="text-red" style="font-size: 14px;">หมายเหตุ : เรียกดูข้อมูลสุขภาพจากระบบได้ <br> เมื่อ scan QR จากผู้ป่วยเท่านั้น</span></v-col>
                </v-row> -->
                              
                
                <checkmong-home-bar title="รายชื่อผู้ป่วยในการดูแล" style="padding-top: 0px !important;"></checkmong-home-bar>
                <br />
                <v-row class="d-flex pt-2">
                    <v-col cols="12" style="text-align: center;padding-bottom: 0px;">
                        <v-card>
                            <span class="text-red" style="font-size: 12px;">หมายเหตุ : การบันทึกข้อมูลสุขภาพจะเชื่อมต่อกับระบบผู้ป่วย <br> เมื่อ scan QR จากผู้ป่วยเท่านั้น</span>
                        </v-card>
                    </v-col>
                </v-row>
                <v-row class="d-flex">
                    <v-col cols="12" style="text-align: right;padding-bottom: 0px;">
                        <v-btn @click="$router.push('/scan_add_patient')"  tile color="bg-orange" small class="text-purple" style="box-shadow: 0px 1px 3px rgba(153, 153, 153, 0.12);border-radius: 7px;">
                            <v-icon left>
                                person_add_alt_1
                            </v-icon>
                            เพิ่มผู้ป่วยในการดูแล
                        </v-btn>
                    </v-col>
                </v-row>
                <br />
                <v-row class="d-flex">
                    <v-col cols="7" style="padding-bottom: 0px;"> <span style="font-size: 14px;">คนไข้ทั้งหมด <b>{{patient_list.length}}</b> คน</span></v-col>
                    <v-col cols="5">
                        <v-row>
                            <v-col cols="12">
                                <v-icon small color="green">noise_control_off</v-icon><span style="font-size:11px;">มีไลน์</span>
                                &nbsp;
                                <v-icon small color="grey">noise_control_off</v-icon><span style="font-size:11px;">ไม่มีไลน์</span>
                            </v-col>
                        </v-row>
                    </v-col>

                </v-row>
                <v-row class="d-flex">
                    <v-col cols="12" style="padding-top: 0px !important;">
                    <v-card elevation="1">
                        <v-text-field v-model="search" append-icon="search" label="" single-line hide-details style="padding-top: 0px;margin-top: 0px;background-color: white;border-top-left-radius: 5px;border-top-right-radius: 5px;" dense flat></v-text-field>
                        <!-- <v-data-table :search="search" :disable-pagination="true" :headers="headers" :items="patient_list" :items-per-page="10" dense mobile-breakpoint @click:row="handleClick"> -->
                            <v-data-table :search="search" :disable-pagination="true" :headers="headers" :items="patient_list" :items-per-page="10" dense mobile-breakpoint >
                            <template v-slot:item.line_status="{ item }">
                                <div v-if="item.line_status">
                                    <v-icon small color="green">noise_control_off</v-icon>
                                </div>
                                <div v-else>
                                    <v-icon small color="grey">noise_control_off</v-icon>
                                </div>
                            </template>
                            <template v-slot:item.del="{ item  }">

                                <v-icon color="red" @click="del_user(item)">
                                    delete
                                </v-icon>


                    </template>
                            <!-- <template v-slot:item.action="{ item }">

                                <div v-if="item.action == false"></div>
                                <div v-else>
                                    <v-btn tile @click="choose_action(item)" color="bg-orange" x-small class="text-purple" style="box-shadow: 0px 1px 3px rgba(153, 153, 153, 0.12);border-radius: 7px;">
                                        ข้อมูลสุขภาพผู้ป่วย
                                    </v-btn>
                                </div>
                            </template> -->
                        </v-data-table>
                        </v-card>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>
        <!-- <v-row v-show="!$vuetify.breakpoint.mobile" class="align-center" justify="center" style="padding-top: 15px">
            <v-btn outlined style="border-color: #d6d6d629" color="purple" class="border-radius-xl">
                <v-icon small>west</v-icon>
            </v-btn>
            &nbsp; &nbsp; &nbsp; &nbsp;
            <v-btn style="width: 250px" color="bg-primary text-white" class="border-radius-xl">
                <v-icon left> control_point </v-icon>
                เพิ่มการนัดหมาย
            </v-btn>
        </v-row>

        <v-footer v-show="$vuetify.breakpoint.mobile" fixed class="action-nav" style="height: 60px">
            <v-row class="align-center" justify="center">
                <v-btn outlined style="border-color: #d6d6d629" color="purple" class="border-radius-xl">
                    <v-icon small>west</v-icon>
                </v-btn>
                &nbsp; &nbsp; &nbsp; &nbsp;
                <v-btn style="width: 250px" color="bg-primary text-white" class="border-radius-xl">
                    <v-icon left> control_point </v-icon>
                    เพิ่มการนัดหมาย
                </v-btn>
            </v-row>
        </v-footer> -->
    </v-container>
</div>
</template>

<script>
import CheckmongHomeBar from "@/components/CheckmongHomeBar.vue";
import {
    APIURL
} from '@/constants'
import Vue from 'vue'
import WaitingModal from "@/components/WaitingModal.vue";
export default {
    name: "calendar-patient",
    components: {
        CheckmongHomeBar,
        WaitingModal
    },
    data: () => ({
        cf_dialog : false,
        patient_list: [],
        active_waiting: false,
        title: '',
        status: '',
        search: '',
        attrs: "",
        on: "",
        selected_row: "",
        dialog: false,
        arrayEvents: null,
        date1: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
            .toISOString()
            .substr(0, 10),
        date2: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
            .toISOString()
            .substr(0, 10),

        headers: [{
                text: "ลำดับ",
                value: "id",
            },
            {
                text: "ชื่อ-นามสกุล",
                value: "patient_name",
            },
            {
                text: "",
                value: "del",
                sortable: false
            },
            {
                text: "",
                value: "line_status",
            },
            // {
            //     text: "",
            //     value: "action",
            //     sortable: false
            // },
        ],
        // patient_list: [{
        //         id: "1",
        //         patient_name: "ชัชวาลย์ รัตนโสภา",
        //         line_status: true,
        //         action: false
        //     },
        //     {
        //         id: "2",
        //         patient_name: "ลลิตา รัตนโสภา",
        //         line_status: false,
        //         action: true
        //     },
        // ],
    }),

    mounted() {
        this.getPatientList()
    },
    watch: {
        menu(val) {
            val && setTimeout(() => (this.activePicker = "YEAR"));
        },
    },
    methods: {
        del_user(val){
            this.selected_row = val
            this.cf_dialog =true
        },
        async delRelation() {
            //console.log('his.selected_row.user_relation_id:',this.selected_row.user_relation_id)
            this.active_waiting = true
            this.title = 'กำลังลบข้อมูล',
                this.status = 'loading'
            await Vue.axios.post(APIURL + '/del_relation', {
                "user_relation_id": this.selected_row.user_relation_id
            }, {
                headers: {
                    'Content-Type': 'application/json'
                }
            }).then((response) => {
                if (response.data) {
                    this.active_waiting = false
                    this.dialog_edit = false
                    this.dialog = false
                    // this.getBookingList()
                    this.$router.go()

                    // this.$store.commit('setUserInfo',response.data);
                    // this.district = response.data.district
                    // this.subdistrict_list = response.data.location_list
                    // this.location_id = response.data.location_list
                } else {
                    this.active_waiting = false

                }

            })
        },
                    async getPatientList() {
            this.active_waiting = true
            this.title = 'กำลังอัพเดต',
                this.status = 'loading'
            await Vue.axios.post(APIURL + '/get_patient_list', {
                "user_id": this.$store.state.login_detail.user_id,
            }, {
                headers: {
                    'Content-Type': 'application/json'
                }
            }).then((response) => {
                //console.log(response)
                if (response.data) {
                    this.active_waiting = false
                    //console.log(response.data)
                    this.patient_list = response.data.patient_list;
                    //console.log('777777777777777')
                    //console.log(this.patient_list)
                } else {
                    this.active_waiting = false
                }

            })

        },
        handleClick(value) {
            this.selected_row = value;
            this.dialog = true;
        },
    },
};
</script>

<style scoped>
.v-card {
    display: flex !important;
    flex-direction: column;
}

.v-card__text {
    flex-grow: 1;
    overflow: auto;
}

.action-nav {
    background: #ffffff !important;
    box-shadow: 0px -2px 4px rgba(207, 202, 202, 0.25) !important;
    border-top-left-radius: 18px !important;
    border-top-right-radius: 18px !important;
    height: 77 px !important;
}

.v-menu__content .v-list-item__title {
    font-size: 0.875rem;
    color: #ffffff;
    color: gray;
}

.custom-field {
    border-radius: 6px;
}

.v-picker__title {
    color: #ffffff;
    border-top-left-radius: 0.375rem;
    border-top-right-radius: 0.375rem;
    padding: 16px;
    background-color: #a84192;
}

.v-main__wrap {
    padding-bottom: 90px;
}

.position-relative {
    position: relative;
}

.v-date-picker-table .v-btn.v-btn--active {
    color: #ffffff;
    background-color: #a84192;
}

.theme--light.v-date-picker-header .v-date-picker-header__value:not(.v-date-picker-header__value--disabled) button:not(:hover):not(:focus) {
    color: #a84192 !important;
}

.theme--light.v-btn.v-btn--icon {
    color: #a84192 !important;
}

.v-dialog>.v-card>.v-card__text {
    padding: 0 24px 0px;
}

.text-start {
    font-size: 12px !important;
    padding-right: 0px !important;
}

.v-application--is-ltr .v-data-footer__select {
    margin-left: 0px !important;
    margin-right: 0px !important;
}

.v-application--is-ltr .v-data-footer__pagination {
    margin: 0 0px 0 14px !important;
    /* margin: 8px; */
}

.v-application--is-ltr .v-data-footer__select .v-select {
    margin: 13px 0 13px 14px !important;
}

.v-data-footer {
    justify-content: end !important;
}

.v-text-field > .v-input__control > .v-input__slot > .v-text-field__slot {
    padding-left: 15px !important;
}
</style>
